<div class="container">
  <div class="container__title">
    <p class="title">本日ご注文の最短お届け日</p>
  </div>
  <div class="container__subtitle">
    <p class="subtitle">**宅急便の場合</p>
  </div>
  <div class="container__date">
    <p class="date">{{ arrivalDate$ | async }}</p>
  </div>
</div>
