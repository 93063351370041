/* eslint-disable @typescript-eslint/naming-convention */
import type { TextStyle } from '@studiobuki/web-core/lib/book-common';

export const CherryBombOne_white_20: TextStyle = {
  fill: '#ffffff',
  fontFamily: 'CherryBombOne',
  fontSize: 20,
  letterSpacing: 1,
  lineHeight: 1.5,
};
export const ZenMaruGothicBold_black_16: TextStyle = {
  fill: '#000000',
  fontFamily: 'ZenMaruGothicBold',
  fontSize: 16,
  letterSpacing: 1,
  lineHeight: 1.5,
};
export const ZenMaruGothicBold_white_16: TextStyle = {
  fill: '#ffffff',
  fontFamily: 'ZenMaruGothicBold',
  fontSize: 16,
  letterSpacing: 1,
  lineHeight: 1.5,
};

export const ZenMaruGothicBold_black_61: TextStyle = {
  fill: '#000000',
  fontFamily: 'ZenMaruGothicBold',
  fontSize: 61,
  letterSpacing: 1,
  lineHeight: 1.5,
};
export const APJapanesefont_black_21: TextStyle = {
  fill: '#000000',
  fontFamily: 'APJapanesefont',
  fontSize: 21,
  letterSpacing: 1,
  lineHeight: 1.5,
};
export const APJapanesefont_black_34: TextStyle = {
  fill: '#000000',
  fontFamily: 'APJapanesefont',
  fontSize: 34,
  letterSpacing: 1,
  lineHeight: 1.5,
};
export const APJapanesefont_black_44: TextStyle = {
  fill: '#000000',
  fontFamily: 'APJapanesefont',
  fontSize: 44,
  letterSpacing: 1,
  lineHeight: 1.5,
};
export const Huifont_black_20: TextStyle = {
  fill: '#000000',
  fontFamily: 'HuiFont',
  fontSize: 20,
  letterSpacing: 1,
  lineHeight: 1.5,
};
export const ArmedBananafont_black_20: TextStyle = {
  fill: '#000000',
  fontFamily: 'ArmedBanana',
  fontSize: 20,
  letterSpacing: 1,
  lineHeight: 1.5,
};
export const LightNovelPOPv2_4D3B43_20: TextStyle = {
  fill: '#4D3B43',
  fontFamily: 'LightNovelPOPv2',
  fontSize: 20,
  letterSpacing: 1,
  lineHeight: 1.5,
};
export const LightNovelPOPv2_4D3B43_35: TextStyle = {
  fill: '#4D3B43',
  fontFamily: 'LightNovelPOPv2',
  fontSize: 35,
  letterSpacing: 1,
  lineHeight: 1.5,
};
