import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { SwiperModule } from 'ngx-swiper-wrapper';
import { SwiperModule } from 'swiper/angular';
// import { PricesComponent } from './components/prices/prices.component';
import { ButtonModule } from '@studiobuki/web-core/lib/button';
import { SectionFeaturesModule } from '@studiobuki/web-core/lib/section-features';
import { SectionReviewsModule } from '@studiobuki/web-core/lib/section-reviews';
import { SectionFaqModule } from '@studiobuki/web-core/lib/section-faq';
import { SectionMessageDateModule } from '@studiobuki/web-core/lib/section-message-date';
import { BookFormModule } from '@studiobuki/web-core/lib/book-form';
import { PreviewModule } from '@studiobuki/web-core/lib/preview';
import { SectionStepsModule } from '@studiobuki/web-core/lib/section-steps';
import { BannerComponent } from './components/banner/banner.component';
import { SectionDetailsComponent } from './section-details.component';

@NgModule({
  declarations: [
    SectionDetailsComponent,
    // PricesComponent,
    BannerComponent,
  ],
  imports: [
    CommonModule,
    SwiperModule,
    BookFormModule,
    ButtonModule,
    SectionFeaturesModule,
    // SectionSeeOtherModule,
    SectionReviewsModule,
    // SectionReviewsYotpoBookModule,
    SectionStepsModule,
    SectionFaqModule,
    SectionMessageDateModule,
    // SectionBannerMermaidModule,
    PreviewModule,
  ],
  exports: [SectionDetailsComponent],
})
export class SectionDetailsModule {}
