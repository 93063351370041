/* eslint-disable @typescript-eslint/naming-convention */
import type { TextElement } from '@studiobuki/web-core/lib/book-common';
import {
  APJapanesefont_black_21,
  APJapanesefont_black_34,
  APJapanesefont_black_44,
  ArmedBananafont_black_20,
  CherryBombOne_white_20,
  Huifont_black_20,
  LightNovelPOPv2_4D3B43_35,
  ZenMaruGothicBold_black_16,
  ZenMaruGothicBold_black_61,
  ZenMaruGothicBold_white_16,
} from './_styles';

// ToDo
const SEGMENT_0_test_value = '111111111111111111111111111';

export const SEGMENT_0_test: TextElement = {
  align: 'left',
  style: CherryBombOne_white_20,
  text: SEGMENT_0_test_value,
  x: 74,
  y: 36,
  xPdf: 74,
  yPdf: 36,
  rotation: -3,
};

const SEGMENT_0_text_value_1 = '{{heroName}}{{kidstitleIndex}}';

export const SEGMENT_0_text_1: TextElement = {
  align: 'center',
  style: ZenMaruGothicBold_black_61,
  text: SEGMENT_0_text_value_1,
  width: 467,
  x: 337,
  y: 209,
  xPdf: 327,
  yPdf: 209,
};
const SEGMENT_0_text_value_2 = 'せかいで見つけた　たからもの';

export const SEGMENT_0_text_2: TextElement = {
  align: 'center',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_0_text_value_2,
  width: 467,
  x: 346,
  y: 302,
  xPdf: 346,
  yPdf: 302,
};

const SEGMENT_2_text_value_1 = `{{heroName}}{{kidstitle}}は　紙ひこうきを　おっています。`;

export const SEGMENT_2_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_2_text_value_1,
  x: 42,
  y: 56,
};
const SEGMENT_2_text_value_2 = 'うーん、なかなか　うまく　とばないなぁ。';

export const SEGMENT_2_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_2_text_value_2,
  x: 342,
  y: 135,
};

const SEGMENT_3_text_value_1 =
  'よぅし、うまくできた！きっとこれなら　だいじょうぶ！';

export const SEGMENT_3_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_3_text_value_1,
  x: 245,
  y: 162,
};
const SEGMENT_3_text_value_2 =
  'こんどは　もっと高く、もっととおくまで　とびますように。';

export const SEGMENT_3_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_3_text_value_2,
  x: 338,
  y: 210,
};

const SEGMENT_3_text_value_3 = 'さぁ、せーの！';

export const SEGMENT_3_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_3_text_value_3,
  x: 676,
  y: 530,
};

const SEGMENT_4_text_value_1 = 'えーい！';

export const SEGMENT_4_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_4_text_value_1,
  x: 221,
  y: 90,
};

const SEGMENT_5_text_value_1 =
  'あっ、すごいぞ！\n風をつかまえて　あんなに高く　とんだ！';

export const SEGMENT_5_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_5_text_value_1,
  x: 240,
  y: 315,
};
const SEGMENT_5_text_value_2 = 'その時です！';

export const SEGMENT_5_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_5_text_value_2,
  x: 658,
  y: 525,
};

const SEGMENT_6_text_value_1 = 'あれ？ここは？';

export const SEGMENT_6_text_1: TextElement = {
  align: 'left',
  style: APJapanesefont_black_34,
  text: SEGMENT_6_text_value_1,
  x: 106,
  y: 38,
};

const SEGMENT_6_text_value_2 =
  'まどからは、大きな　つばさがみえます。\nパスポートと、とうじょうけんを　もっています。\nもしかしてこれは、ひこうきの中！';

export const SEGMENT_6_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_white_16,
  text: SEGMENT_6_text_value_2,
  x: 38,
  y: 375,
};
const SEGMENT_6_text_value_3 =
  '「ごとうじょう　ありがとうございます。間もなく　りりくいたします」\nと、アナウンスがきこえました！';

export const SEGMENT_6_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_white_16,
  text: SEGMENT_6_text_value_3,
  x: 135,
  y: 493,
};
const SEGMENT_7_text_value_1 = 'ということは、今から空を　とんじゃうの？';

export const SEGMENT_7_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_7_text_value_1,
  x: 481,
  y: 23,
};
const SEGMENT_7_text_value_2 = '{{heroName}}{{kidstitle}}';

export const SEGMENT_7_text_2: TextElement = {
  align: 'center',
  style: LightNovelPOPv2_4D3B43_35,
  text: SEGMENT_7_text_value_2,
  width: 325,
  rotation: 19,
  x: 464,
  y: 171,
};

const SEGMENT_8_text_value_1 =
  'ひこうきは　ゆっくりと　うごきだし、\nぐんぐんと　スピードをあげて　走り出しました！';

export const SEGMENT_8_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_8_text_value_1,
  x: 36,
  y: 66,
};

const SEGMENT_8_text_value_2_1 = 'あっ！';

export const SEGMENT_8_text_2_1: TextElement = {
  align: 'left',
  style: APJapanesefont_black_44,
  text: SEGMENT_8_text_value_2_1,
  x: 291,
  y: 433,
};
const SEGMENT_8_text_value_2_2 = 'ふわっとういた！';

export const SEGMENT_8_text_2_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_8_text_value_2_2,
  x: 416,
  y: 461,
};

const SEGMENT_8_text_value_3 = '上へ、上へ。';

export const SEGMENT_8_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_8_text_value_3,
  x: 599,
  y: 499,
};

const SEGMENT_8_text_value_4 = 'いったい　どこまで　あがるんだろう。';

export const SEGMENT_8_text_4: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_8_text_value_4,
  x: 406,
  y: 546,
};

const SEGMENT_9_text_value_1 = 'どんどん　じめんが　遠ざかっていきます。';

export const SEGMENT_9_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_9_text_value_1,
  x: 41,
  y: 53,
};
const SEGMENT_9_text_value_2 =
  '白いモヤモヤしたものに　つつまれたと　思ったら、';

export const SEGMENT_9_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_9_text_value_2,
  x: 102,
  y: 94,
};
const SEGMENT_9_text_value_3 = 'ひろくひろく、青い空が　あらわれました！';

export const SEGMENT_9_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_9_text_value_3,
  x: 444,
  y: 465,
};
const SEGMENT_9_text_value_4 =
  '白いモヤモヤは、なんと、雲！雲より　高いところにいるなんて！';

export const SEGMENT_9_text_4: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_9_text_value_4,
  x: 275,
  y: 513,
};

const SEGMENT_10_text_value_1 =
  '「きないしょくを　どうぞ」と、きゃくしつじょうむいんさんが　ごはんをくれました。';

export const SEGMENT_10_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_10_text_value_1,
  x: 48,
  y: 43,
};

const SEGMENT_11_text_value_1 =
  '今、空の上を　とんでいるって、本当なんでしょうか？';

export const SEGMENT_11_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_11_text_value_1,
  x: 170,
  y: 26,
};
const SEGMENT_11_text_value_2 = 'なんだか　しんじられない　きもちです。';

export const SEGMENT_11_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_11_text_value_2,
  x: 504,
  y: 75,
};
const SEGMENT_11_text_value_3 =
  'えいがも見れて、おいしいごはんも　食べられる。\nこんなに　楽しいんですからっ！';

export const SEGMENT_11_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_11_text_value_3,
  x: 446,
  y: 504,
};

const SEGMENT_12_16_text_value_1 =
  'しばらくすると「間もなく　ちゃくりくたいせいに入ります」と、\nパイロットの　アナウンスが　聞こえました。';

export const SEGMENT_12_16_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_12_16_text_value_1,
  x: 36,
  y: 35,
};
const SEGMENT_12_16_text_value_2 =
  'ひこうきは　こんどは　上から下に　むかっていきます。';

export const SEGMENT_12_16_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_12_16_text_value_2,
  x: 349,
  y: 133,
};

const SEGMENT_12_16_text_value_3 =
  'すると！つばさの下に　まちが見えてきました！';
export const SEGMENT_12_16_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_12_16_text_value_3,
  x: 368,
  y: 533,
};

const SEGMENT_12_17_text_value_1 =
  'しばらくすると「間もなく　ちゃくりくたいせいに入ります」と、\nパイロットの　アナウンスが　聞こえました。';

export const SEGMENT_12_17_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_12_17_text_value_1,
  x: 36,
  y: 35,
};
const SEGMENT_12_17_text_value_2 =
  'ひこうきは　こんどは　上から下に　むかっていきます。';

export const SEGMENT_12_17_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_12_17_text_value_2,
  x: 349,
  y: 133,
};

const SEGMENT_12_17_text_value_3 =
  'すると！つばさの下に　まちが見えてきました！';
export const SEGMENT_12_17_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_12_17_text_value_3,
  x: 368,
  y: 533,
};

const SEGMENT_12_18_text_value_1 =
  'しばらくすると「間もなく　ちゃくりくたいせいに入ります」と、\nパイロットの　アナウンスが　聞こえました。';

export const SEGMENT_12_18_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_12_18_text_value_1,
  x: 36,
  y: 35,
};
const SEGMENT_12_18_text_value_2 =
  'ひこうきは　こんどは　上から下に　むかっていきます。';

export const SEGMENT_12_18_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_12_18_text_value_2,
  x: 349,
  y: 133,
};

const SEGMENT_12_18_text_value_3 =
  'すると！つばさの下に　まちが見えてきました！';
export const SEGMENT_12_18_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_12_18_text_value_3,
  x: 368,
  y: 533,
};

const SEGMENT_13_19_text_value_1 = 'ひこうきは　じめんに　ちゃくりくしました。';
export const SEGMENT_13_19_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_13_19_text_value_1,
  x: 404,
  y: 533,
};

const SEGMENT_13_20_text_value_1 = 'ひこうきは　じめんに　ちゃくりくしました。';
export const SEGMENT_13_20_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_13_20_text_value_1,
  x: 404,
  y: 533,
};
const SEGMENT_13_21_text_value_1 = 'ひこうきは　じめんに　ちゃくりくしました。';
export const SEGMENT_13_21_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_13_21_text_value_1,
  x: 404,
  y: 533,
};

const SEGMENT_20_text_value_1 =
  'つぎの国にいくために、{{heroName}}{{kidstitle}}は　ひこうきに　のり込みます。';

export const SEGMENT_20_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_20_text_value_1,
  x: 48,
  y: 54,
};
const SEGMENT_20_text_value_2 =
  'よく見れば、ひこうきって　なんて大きいんでしょう！';

export const SEGMENT_20_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_20_text_value_2,
  x: 192,
  y: 140,
};

const SEGMENT_21_text_value_1 = 'これが、空をとぶなんて、すごいなぁ。';

export const SEGMENT_21_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_21_text_value_1,
  x: 497,
  y: 135,
};
const SEGMENT_21_text_value_2 =
  'こんなに　大きくっておもそうな　ひこうきが　空をとべるんなら、\n{{heroName}}{{kidstitle}}も　とべるんじゃないの？と、{{heroName}}{{kidstitle}}は　思いました。';

export const SEGMENT_21_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_21_text_value_2,
  x: 168,
  y: 521,
};

const SEGMENT_28_text_value_1 =
  '「すごい！せかいは　こんなところが　あるなんて！」\nくうこうで　{{heroName}}{{kidstitle}}は　思いました。';

export const SEGMENT_28_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_28_text_value_1,
  x: 30,
  y: 314,
};
const SEGMENT_28_text_value_2 =
  'そのとき、\n「楽しかったかい？」とパイロットが　{{heroName}}{{kidstitle}}に　聞きました。';

export const SEGMENT_28_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_28_text_value_2,
  x: 33,
  y: 523,
};

const SEGMENT_29_text_value_1 =
  '「うん、とっても！」{{heroName}}{{kidstitle}}が　答えます。';

export const SEGMENT_29_text_1: TextElement = {
  align: 'right',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_29_text_value_1,
  width: 842,
  x: -44,
  y: 109,
};
const SEGMENT_29_text_value_2 =
  '「せかいは、広い。{{heroName}}{{kidstitle}}が　知らないこと、見たことがないものが、たくさんある。\nでも、{{heroName}}{{kidstitle}}の　{{strengthE}}があれば、たからものを　見つけることができるよ」';

export const SEGMENT_29_text_2: TextElement = {
  align: 'right',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_29_text_value_2,
  width: 842,
  x: -40,
  y: 176,
};

const SEGMENT_29_text_value_3 =
  '「はい、これは　{{heroName}}{{kidstitle}}の　ものだね」\nそういって　パイロットは　紙ひこうきを　{{heroName}}{{kidstitle}}に　手わたしました。';

export const SEGMENT_29_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_29_text_value_3,
  x: 185,
  y: 523,
};

const SEGMENT_30_text_value_1 =
  'そして、気がつくとそこは、{{heroName}}{{kidstitle}}の　へやの中。';

export const SEGMENT_30_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_30_text_value_1,
  x: 61,
  y: 76,
};
const SEGMENT_30_text_value_2 =
  '「ごはんだよー！」\nと、{{heroName}}{{kidstitle}}を　よぶ　声がします。';

export const SEGMENT_30_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_30_text_value_2,
  x: 129,
  y: 139,
};
const SEGMENT_30_text_value_3 = 'もしかして、ぜんぶ　ゆめだったのでしょうか？';

export const SEGMENT_30_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_30_text_value_3,
  x: 230,
  y: 518,
};

const SEGMENT_31_text_value_1 = 'ゆめだったのかどうかは　わかりません。';

export const SEGMENT_31_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_31_text_value_1,
  x: 348,
  y: 114,
};
const SEGMENT_31_text_value_2 =
  'でも{{heroName}}{{kidstitle}}は、\n{{Country01}}も、{{Country02}}も　おぼえている　気がします。';

export const SEGMENT_31_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_31_text_value_2,
  x: 169,
  y: 449,
};
const SEGMENT_31_text_value_3 =
  'つくえの上には、もらった　プレゼントが　おいてあります。';

export const SEGMENT_31_text_3: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_31_text_value_3,
  x: 360,
  y: 534,
};

const SEGMENT_32_text_value_1 =
  '「今いくよー！」{{heroName}}{{kidstitle}}は　へんじをしました。';

export const SEGMENT_32_text_1: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_32_text_value_1,
  x: 50,
  y: 475,
};
const SEGMENT_32_text_value_2 =
  'そして、手にもっていた、さっきとばしたはずの　紙ひこうきを　もういちど、\n力づよく　とばしました。';

export const SEGMENT_32_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_32_text_value_2,
  x: 57,
  y: 516,
};

const SEGMENT_33_text_value_1 = '{{heroName}}{{kidstitle}}';

export const SEGMENT_33_text_1: TextElement = {
  align: 'center',
  style: APJapanesefont_black_21,
  text: SEGMENT_33_text_value_1,
  rotation: -9,
  width: 178,
  x: 188,
  y: 146,
};
const SEGMENT_33_text_value_2 = '「とんでけー！もっと、とおくまで！」';

export const SEGMENT_33_text_2: TextElement = {
  align: 'left',
  style: ZenMaruGothicBold_black_16,
  text: SEGMENT_33_text_value_2,
  x: 481,
  y: 34,
};

const SEGMENT_34_text_value_1 =
  'いつまでも　{{strengthF}}\n{{heroName}}{{kidstitle}}で　いてね。\nまた　あおうね。\n{{from1}}より';

export const SEGMENT_34_text_1: TextElement = {
  align: 'left',
  style: Huifont_black_20,
  text: SEGMENT_34_text_value_1,
  rotation: 11,
  x: 127,
  y: 40,
};
const SEGMENT_34_text_value_2 =
  'いつまでも　{{strengthF}}\n{{heroName}}{{kidstitle}}で　いてね。\nまた　あおうね。\n{{from1}}より';

export const SEGMENT_34_text_2: TextElement = {
  align: 'center',
  style: ArmedBananafont_black_20,
  text: SEGMENT_34_text_value_2,
  width: 500,
  rotation: -12,
  x: 329,
  y: 456,
};
