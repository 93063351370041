import type { IBookTravelData } from '@studiobuki/shared/dist/jp/models';

import { resolveAssetElement } from '@studiobuki/web-core/lib/book-common';
import type {
  ImageElement,
  TextElement,
  AssetElement,
  TermsWelcomeToTheWold,
  PageTermsWelcomeToTheWold,
  Segment,
  Page,
} from '@studiobuki/web-core/lib/book-common';

import { isNode } from 'browser-or-node';
import type {
  TClothColorTravel,
  TDestinations,
  THairColorTravel,
  THairStyleTravel,
  TKunChan,
  TSkinColorTravel,
  TStrength,
} from '@studiobuki/shared/dist/jp/book/interfaces';
import { KUNCHAN_VALUE_TO_LABEL_MAP } from '@studiobuki/shared/dist/jp/book/maps';
import {
  INPUT_VALUE_DESTINATION_HAWAI,
  INPUT_VALUE_DESTINATION_ENGLAND,
  INPUT_VALUE_DESTINATION_FINLAND,
  INPUT_VALUE_DESTINATION_INDIA,
} from '@studiobuki/shared/dist/jp/book/constants';
import { sortByArray } from '@studiobuki/web-core';
import { jp } from './locale';
import { ZenMaruGothicBold_black_16 } from './locale/jp/_styles';
import {
  COUNTRY01,
  COUNTRY02,
  FROM1,
  FROM2,
  KEY_KIDS_TITLE,
  KEY_KIDS_TITLE_INDEX,
  KEY_STRENGT,
  STRENGTH,
} from './_constants';

type TSegmentTermsType = TermsWelcomeToTheWold;
type TPageTermsType = PageTermsWelcomeToTheWold;
type TSegment = Segment<
  TSegmentTermsType,
  TPageTermsType,
  IBookTravelData,
  'jp'
>;

const skinColorConfig: {
  [clothersColor in TClothColorTravel]: {
    [skinColor in TSkinColorTravel]:
      | ImageElement
      | AssetElement<{ assetSkin: string }>;
  };
} = {
  green: {
    silver: {
      type: 'AssetElement',
      asset: {
        assetSkin: '01',
      },
    },
    white: {
      type: 'AssetElement',
      asset: {
        assetSkin: '02',
      },
    },
    nutmeg: {
      type: 'AssetElement',
      asset: {
        assetSkin: '03',
      },
    },
    brown: {
      type: 'AssetElement',
      asset: {
        assetSkin: '04',
      },
    },
    black: {
      type: 'AssetElement',
      asset: {
        assetSkin: '05',
      },
    },
  },

  red: {
    silver: {
      type: 'AssetElement',
      asset: {
        assetSkin: '06',
      },
    },
    white: {
      type: 'AssetElement',
      asset: {
        assetSkin: '07',
      },
    },
    nutmeg: {
      type: 'AssetElement',
      asset: {
        assetSkin: '08',
      },
    },
    brown: {
      type: 'AssetElement',
      asset: {
        assetSkin: '09',
      },
    },
    black: {
      type: 'AssetElement',
      asset: {
        assetSkin: '10',
      },
    },
  },
};

const hairColorConfig: {
  [hairColor in THairColorTravel]: {
    [hairStyle in THairStyleTravel]:
      | ImageElement
      | AssetElement<{ assetHair: string }>;
  };
} = {
  silver: {
    shaved: {
      type: 'AssetElement',
      asset: {
        assetHair: '01',
      },
    },
    scarf: {
      type: 'AssetElement',
      asset: {
        assetHair: '02',
      },
    },
    very_short: {
      type: 'AssetElement',
      asset: {
        assetHair: '03',
      },
    },
    short: {
      type: 'AssetElement',
      asset: {
        assetHair: '04',
      },
    },
    short_bob: {
      type: 'AssetElement',
      asset: {
        assetHair: '05',
      },
    },
    long_bang: {
      type: 'AssetElement',
      asset: {
        assetHair: '06',
      },
    },
    medium: {
      type: 'AssetElement',
      asset: {
        assetHair: '07',
      },
    },
    bob: {
      type: 'AssetElement',
      asset: {
        assetHair: '08',
      },
    },
    long: {
      type: 'AssetElement',
      asset: {
        assetHair: '09',
      },
    },
    very_long: {
      type: 'AssetElement',
      asset: {
        assetHair: '10',
      },
    },
    short_wave: {
      type: 'AssetElement',
      asset: {
        assetHair: '11',
      },
    },
    long_wave: {
      type: 'AssetElement',
      asset: {
        assetHair: '12',
      },
    },
    short_curl: {
      type: 'AssetElement',
      asset: {
        assetHair: '13',
      },
    },
    long_curl: {
      type: 'AssetElement',
      asset: {
        assetHair: '14',
      },
    },
  },
  blonde: {
    shaved: {
      type: 'AssetElement',
      asset: {
        assetHair: '15',
      },
    },
    scarf: {
      type: 'AssetElement',
      asset: {
        assetHair: '16',
      },
    },
    very_short: {
      type: 'AssetElement',
      asset: {
        assetHair: '17',
      },
    },
    short: {
      type: 'AssetElement',
      asset: {
        assetHair: '18',
      },
    },
    short_bob: {
      type: 'AssetElement',
      asset: {
        assetHair: '19',
      },
    },
    long_bang: {
      type: 'AssetElement',
      asset: {
        assetHair: '20',
      },
    },
    medium: {
      type: 'AssetElement',
      asset: {
        assetHair: '21',
      },
    },
    bob: {
      type: 'AssetElement',
      asset: {
        assetHair: '22',
      },
    },
    long: {
      type: 'AssetElement',
      asset: {
        assetHair: '23',
      },
    },
    very_long: {
      type: 'AssetElement',
      asset: {
        assetHair: '24',
      },
    },
    short_wave: {
      type: 'AssetElement',
      asset: {
        assetHair: '25',
      },
    },
    long_wave: {
      type: 'AssetElement',
      asset: {
        assetHair: '26',
      },
    },
    short_curl: {
      type: 'AssetElement',
      asset: {
        assetHair: '27',
      },
    },
    long_curl: {
      type: 'AssetElement',
      asset: {
        assetHair: '28',
      },
    },
  },
  red: {
    shaved: {
      type: 'AssetElement',
      asset: {
        assetHair: '29',
      },
    },
    scarf: {
      type: 'AssetElement',
      asset: {
        assetHair: '30',
      },
    },
    very_short: {
      type: 'AssetElement',
      asset: {
        assetHair: '31',
      },
    },
    short: {
      type: 'AssetElement',
      asset: {
        assetHair: '32',
      },
    },
    short_bob: {
      type: 'AssetElement',
      asset: {
        assetHair: '33',
      },
    },
    long_bang: {
      type: 'AssetElement',
      asset: {
        assetHair: '34',
      },
    },
    medium: {
      type: 'AssetElement',
      asset: {
        assetHair: '35',
      },
    },
    bob: {
      type: 'AssetElement',
      asset: {
        assetHair: '36',
      },
    },
    long: {
      type: 'AssetElement',
      asset: {
        assetHair: '37',
      },
    },
    very_long: {
      type: 'AssetElement',
      asset: {
        assetHair: '38',
      },
    },
    short_wave: {
      type: 'AssetElement',
      asset: {
        assetHair: '39',
      },
    },
    long_wave: {
      type: 'AssetElement',
      asset: {
        assetHair: '40',
      },
    },
    short_curl: {
      type: 'AssetElement',
      asset: {
        assetHair: '41',
      },
    },
    long_curl: {
      type: 'AssetElement',
      asset: {
        assetHair: '42',
      },
    },
  },
  brown: {
    shaved: {
      type: 'AssetElement',
      asset: {
        assetHair: '43',
      },
    },
    scarf: {
      type: 'AssetElement',
      asset: {
        assetHair: '44',
      },
    },
    very_short: {
      type: 'AssetElement',
      asset: {
        assetHair: '45',
      },
    },
    short: {
      type: 'AssetElement',
      asset: {
        assetHair: '46',
      },
    },
    short_bob: {
      type: 'AssetElement',
      asset: {
        assetHair: '47',
      },
    },
    long_bang: {
      type: 'AssetElement',
      asset: {
        assetHair: '48',
      },
    },
    medium: {
      type: 'AssetElement',
      asset: {
        assetHair: '49',
      },
    },
    bob: {
      type: 'AssetElement',
      asset: {
        assetHair: '50',
      },
    },
    long: {
      type: 'AssetElement',
      asset: {
        assetHair: '51',
      },
    },
    very_long: {
      type: 'AssetElement',
      asset: {
        assetHair: '52',
      },
    },
    short_wave: {
      type: 'AssetElement',
      asset: {
        assetHair: '53',
      },
    },
    long_wave: {
      type: 'AssetElement',
      asset: {
        assetHair: '54',
      },
    },
    short_curl: {
      type: 'AssetElement',
      asset: {
        assetHair: '55',
      },
    },
    long_curl: {
      type: 'AssetElement',
      asset: {
        assetHair: '56',
      },
    },
  },
  black: {
    shaved: {
      type: 'AssetElement',
      asset: {
        assetHair: '57',
      },
    },
    scarf: {
      type: 'AssetElement',
      asset: {
        assetHair: '58',
      },
    },
    very_short: {
      type: 'AssetElement',
      asset: {
        assetHair: '59',
      },
    },
    short: {
      type: 'AssetElement',
      asset: {
        assetHair: '60',
      },
    },
    short_bob: {
      type: 'AssetElement',
      asset: {
        assetHair: '61',
      },
    },
    long_bang: {
      type: 'AssetElement',
      asset: {
        assetHair: '62',
      },
    },
    medium: {
      type: 'AssetElement',
      asset: {
        assetHair: '63',
      },
    },
    bob: {
      type: 'AssetElement',
      asset: {
        assetHair: '64',
      },
    },
    long: {
      type: 'AssetElement',
      asset: {
        assetHair: '65',
      },
    },
    very_long: {
      type: 'AssetElement',
      asset: {
        assetHair: '66',
      },
    },
    short_wave: {
      type: 'AssetElement',
      asset: {
        assetHair: '67',
      },
    },
    long_wave: {
      type: 'AssetElement',
      asset: {
        assetHair: '68',
      },
    },
    short_curl: {
      type: 'AssetElement',
      asset: {
        assetHair: '69',
      },
    },
    long_curl: {
      type: 'AssetElement',
      asset: {
        assetHair: '70',
      },
    },
  },
};

/**
01	Hawaii+England
02	Hawaii+Finalnd
03	Hawaii+India
04	England+Finalnd
05	England+India
06	Finalnd+India
 */
// const destinationsConfig
const destinationsOrder: TDestinations[] = [
  INPUT_VALUE_DESTINATION_HAWAI,
  INPUT_VALUE_DESTINATION_ENGLAND,
  INPUT_VALUE_DESTINATION_FINLAND,
  INPUT_VALUE_DESTINATION_INDIA,
];

const destinationsMap = {
  [`${INPUT_VALUE_DESTINATION_HAWAI}${INPUT_VALUE_DESTINATION_ENGLAND}`]: '01',
  [`${INPUT_VALUE_DESTINATION_HAWAI}${INPUT_VALUE_DESTINATION_FINLAND}`]: '02',
  [`${INPUT_VALUE_DESTINATION_HAWAI}${INPUT_VALUE_DESTINATION_INDIA}`]: '03',
  [`${INPUT_VALUE_DESTINATION_ENGLAND}${INPUT_VALUE_DESTINATION_FINLAND}`]:
    '04',
  [`${INPUT_VALUE_DESTINATION_ENGLAND}${INPUT_VALUE_DESTINATION_INDIA}`]: '05',
  [`${INPUT_VALUE_DESTINATION_FINLAND}${INPUT_VALUE_DESTINATION_INDIA}`]: '06',
} as const;

const getSortedDestinations = (destinations: TDestinations[]) => {
  const [dest1, dest2] = destinations.sort((a, b) =>
    sortByArray(a, b, destinationsOrder),
  );

  if (!dest1) throw new Error('!dest1');
  if (!dest2) throw new Error('!dest2');

  return [dest1, dest2] as const;
};

const getDestinationsMapValue = (destinations: TDestinations[]) => {
  const [dest1, dest2] = getSortedDestinations(destinations);

  const key = `${dest1}${dest2}` as const;

  if (destinationsMap.hasOwnProperty(key)) {
    return destinationsMap[key as keyof typeof destinationsMap];
  }

  throw new Error(
    `key "${key}" doesn't exist in ${JSON.stringify(destinationsMap, undefined, 2)}`,
  );
};

// const destinationsConfig: {
//   [destination1 in TDestinations]: {
//     [destination2 in TDestinations]:
//       | ImageElement
//       | AssetElement<{ assetDestination: string }>;
//   };
// } = {
//   Hawai: {
//     England: {
//       type: 'AssetElement',
//       asset: {
//         assetDestination: '01',
//       },
//     },
//     Finland: {
//       type: 'AssetElement',
//       asset: {
//         assetDestination: '02',
//       },
//     },
//     India: {
//       type: 'AssetElement',
//       asset: {
//         assetDestination: '03',
//       },
//     },
//     Hawai: {
//       type: 'AssetElement',
//       asset: {
//         assetDestination: '00',
//       },
//     },
//   },
//   England: {
//     England: {
//       type: 'AssetElement',
//       asset: {
//         assetDestination: '00',
//       },
//     },
//     Finland: {
//       type: 'AssetElement',
//       asset: {
//         assetDestination: '04',
//       },
//     },
//     India: {
//       type: 'AssetElement',
//       asset: {
//         assetDestination: '05',
//       },
//     },
//     Hawai: {
//       type: 'AssetElement',
//       asset: {
//         assetDestination: '01',
//       },
//     },
//   },
//   Finland: {
//     England: {
//       type: 'AssetElement',
//       asset: {
//         assetDestination: '04',
//       },
//     },
//     Finland: {
//       type: 'AssetElement',
//       asset: {
//         assetDestination: '00',
//       },
//     },
//     India: {
//       type: 'AssetElement',
//       asset: {
//         assetDestination: '06',
//       },
//     },
//     Hawai: {
//       type: 'AssetElement',
//       asset: {
//         assetDestination: '02',
//       },
//     },
//   },
//   India: {
//     England: {
//       type: 'AssetElement',
//       asset: {
//         assetDestination: '05',
//       },
//     },
//     Finland: {
//       type: 'AssetElement',
//       asset: {
//         assetDestination: '06',
//       },
//     },
//     India: {
//       type: 'AssetElement',
//       asset: {
//         assetDestination: '00',
//       },
//     },
//     Hawai: {
//       type: 'AssetElement',
//       asset: {
//         assetDestination: '03',
//       },
//     },
//   },
// };

const ONE_LETTER_KUNCHAN: { [key in TKunChan]: string } = {
  kun: 'a',
  chan: 'b',
  san: 'c',
  nashi: '',
};

const STRENGTH_TO_SHOW: { [key in TStrength]: string } = {
  kind: 'やさしさ',
  brave: 'ゆうかんさ',
  creative: 'はっそうりょく',
};

const segment12Pages: {
  [key in TDestinations]: Page<TPageTermsType>[];
} = {
  Hawai: [
    {
      pageId: '1216',
      pageImageURL: 'assets/templates_jal_travel_book/12016/12016.jpg',
      text: [
        jp.SEGMENT_12_16_text_1,
        jp.SEGMENT_12_16_text_2,
        jp.SEGMENT_12_16_text_3,
      ],
      data: {},
    },
  ],
  England: [
    {
      pageId: '1217',
      pageImageURL: 'assets/templates_jal_travel_book/12017/12017.jpg',
      text: [
        jp.SEGMENT_12_17_text_1,
        jp.SEGMENT_12_17_text_2,
        jp.SEGMENT_12_17_text_3,
      ],
      data: {},
    },
  ],
  Finland: [
    {
      pageId: '1218',
      pageImageURL: 'assets/templates_jal_travel_book/12018/12018.jpg',
      text: [
        jp.SEGMENT_12_18_text_1,
        jp.SEGMENT_12_18_text_2,
        jp.SEGMENT_12_18_text_3,
      ],
      data: {},
    },
  ],
  India: [
    {
      pageId: '12',
      pageImageURL: 'assets/templates_jal_travel_book/12018/12018.jpg',
      text: [jp.SEGMENT_0_test],
      data: {},
    },
  ],
};
const segment13Pages: {
  [key in TDestinations]: Page<TPageTermsType>[];
} = {
  Hawai: [
    {
      pageId: '1319',
      pageImageURL: 'assets/templates_jal_travel_book/13019/13019.jpg',
      text: [jp.SEGMENT_13_19_text_1],
      data: {},
    },
  ],
  England: [
    {
      pageId: '1319',
      pageImageURL: 'assets/templates_jal_travel_book/13019/13019.jpg',
      text: [jp.SEGMENT_13_19_text_1],
      data: {},
    },
  ],
  Finland: [
    {
      pageId: '1320',
      pageImageURL: 'assets/templates_jal_travel_book/13020/13020.jpg',
      text: [jp.SEGMENT_13_20_text_1],
      data: {},
    },
  ],
  India: [
    {
      pageId: '1321',
      pageImageURL: 'assets/templates_jal_travel_book/13021/13021.jpg',
      text: [jp.SEGMENT_13_21_text_1],
      data: {},
    },
  ],
};

export const textProcessor = (
  bookData: IBookTravelData,
  _textStr: string,
): string => {
  let textStr = _textStr;
  let country1JP = '';
  let country2JP = '';
  let from1JP = '';
  let from2JP = '';
  let strenthJP = '';
  const country1 = bookData.destinations[0] as string;
  const country2 = bookData.destinations[1] as string;
  const strenth = bookData.strength;

  textStr = textStr.replace(
    new RegExp(`${KEY_KIDS_TITLE_INDEX}`, 'gi'),
    ONE_LETTER_KUNCHAN[bookData.kunChan],
  );

  textStr = textStr.replace(
    new RegExp(`${KEY_KIDS_TITLE}`, 'gi'),
    KUNCHAN_VALUE_TO_LABEL_MAP[bookData.kunChan],
  );
  textStr = textStr.replace(
    new RegExp(`${KEY_STRENGT}`, 'gi'),
    STRENGTH_TO_SHOW[bookData.strength],
  );

  switch (country1) {
    case 'Hawai':
      country1JP = 'フィンランドのなみのおと';
      from1JP = 'マノ';
      break;
    case 'England':
      country1JP = 'アンナ';
      from1JP = 'マノ';
      break;
    case 'Finland':
      country1JP = 'ニコ';
      from1JP = 'マノ';
      break;
    case 'India':
      country1JP = 'インドのしげきてきなかおり';
      break;
    default:
      console.log(`${country1}  = country1 error`);
      break;
  }
  switch (country2) {
    case 'Hawai':
      country2JP = 'フィンランドのなみのおと';
      break;
    case 'England':
      country2JP = 'ロンドンのにぎやかさ';
      from2JP = 'アンナ';
      break;
    case 'Finland':
      country2JP = 'フィンランドのつめたくすんだくうき';
      from2JP = 'ニコ';
      break;
    case 'India':
      country2JP = 'インドのしげきてきなかおり';
      from2JP = 'ダーシャ';
      break;
    default:
      console.log(`${country2}  = country2 error`);
      break;
  }

  switch (strenth) {
    case 'kind':
      strenthJP = 'やさしい';
      break;
    case 'brave':
      strenthJP = 'ゆうかんな';
      break;
    case 'creative':
      strenthJP = 'はっそうゆたかな';
      break;

    default:
      console.log(`${strenth}  = strenth error`);
      break;
  }

  textStr = textStr.replace(new RegExp(`${COUNTRY01}`, 'gi'), country1JP);
  textStr = textStr.replace(new RegExp(`${COUNTRY02}`, 'gi'), country2JP);
  textStr = textStr.replace(new RegExp(`${FROM1}`, 'gi'), from1JP);
  textStr = textStr.replace(new RegExp(`${FROM2}`, 'gi'), from2JP);
  textStr = textStr.replace(new RegExp(`${STRENGTH}`, 'gi'), strenthJP);

  return textStr;
};

const segments = [
  {
    segmentId: '0',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    objectProcessor: (bookData) => {
      const objects: (ImageElement | TextElement)[] = [];
      const patternSkin: ImageElement = {
        type: 'ImageElement',
        x: 9,
        y: 0,
        imageURL:
          'assets/templates_jal_travel_book/00002/M/00002-M{{assetSkin}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/00002/M/00002-M{{assetSkin}}.pdf',
        depth: 1,
      };
      const patternHair: ImageElement = {
        type: 'ImageElement',
        x: 9,
        y: 0,
        imageURL:
          'assets/templates_jal_travel_book/00002/H/00002-H{{assetHair}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/00002/H/00002-H{{assetHair}}.pdf',
        depth: 2,
      };
      const patternGlasses: ImageElement = {
        type: 'ImageElement',
        x: 9,
        y: 0,
        imageURL: 'assets/templates_jal_travel_book/00002/G/00002-G01.png',
        imagePDFURL: 'assets/templates_jal_travel_book/00002/G/00002-G01.pdf',
        depth: 1,
      };
      objects.push(
        ...[
          resolveAssetElement(
            skinColorConfig[bookData.clothColor][bookData.skinColor],
            patternSkin,
          ),
          resolveAssetElement(
            hairColorConfig[bookData.hairColor][bookData.hairStyle],
            patternHair,
          ),
        ],
      );

      if (bookData.glasses) {
        objects.push(patternGlasses);
      }
      return objects;
    },
    pages: [
      {
        pageId: '0',
        pageImageURL: 'assets/templates_jal_travel_book/00002/00002.jpg',
        text: [jp.SEGMENT_0_text_1, jp.SEGMENT_0_text_2],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '1',
    pages: [
      {
        pageId: '1',
        pageImageURL: 'assets/templates_jal_travel_book/01005/01005.jpg',

        data: {
          messageRect: {
            x: 208,
            y: 145,
            xPdf: 113,
            yPdf: 170,
            width: isNode ? 389 : 389,
            height: 307,
            style: {
              ZenMaruGothicBold_black_16,
            },
          },
        },
      },
    ],
  } as TSegment,
  {
    segmentId: '2',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    objectProcessor: (bookData) => {
      const objects: (ImageElement | TextElement)[] = [];
      const patternSkin: ImageElement = {
        type: 'ImageElement',
        x: 352,
        y: 130,
        imageURL:
          'assets/templates_jal_travel_book/02006/M/02006-M{{assetSkin}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/02006/M/02006-M{{assetSkin}}.pdf',
        depth: 1,
      };
      const patternHair: ImageElement = {
        type: 'ImageElement',
        x: 352,
        y: 130,
        imageURL:
          'assets/templates_jal_travel_book/02006/H/02006-H{{assetHair}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/02006/H/02006-H{{assetHair}}.pdf',
        depth: 2,
      };
      const patternGlasses: ImageElement = {
        type: 'ImageElement',
        x: 352,
        y: 130,
        imageURL: 'assets/templates_jal_travel_book/02006/G/02006-G01.png',
        imagePDFURL: 'assets/templates_jal_travel_book/02006/G/02006-G01.pdf',
        depth: 1,
      };
      objects.push(
        ...[
          resolveAssetElement(
            skinColorConfig[bookData.clothColor][bookData.skinColor],
            patternSkin,
          ),
          resolveAssetElement(
            hairColorConfig[bookData.hairColor][bookData.hairStyle],
            patternHair,
          ),
        ],
      );

      if (bookData.glasses) {
        objects.push(patternGlasses);
      }
      return objects;
    },
    pages: [
      {
        pageId: '2',
        pageImageURL: 'assets/templates_jal_travel_book/02006/02006.jpg',
        text: [jp.SEGMENT_2_text_1, jp.SEGMENT_2_text_2],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '3',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    objectProcessor: (bookData) => {
      const objects: (ImageElement | TextElement)[] = [];
      const patternSkin: ImageElement = {
        type: 'ImageElement',
        x: 313,
        y: 204,
        imageURL:
          'assets/templates_jal_travel_book/03007/M/03007-M{{assetSkin}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/03007/M/03007-M{{assetSkin}}.pdf',
        depth: 1,
      };
      const patternHair: ImageElement = {
        type: 'ImageElement',
        x: 313,
        y: 204,
        imageURL:
          'assets/templates_jal_travel_book/03007/H/03007-H{{assetHair}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/03007/H/03007-H{{assetHair}}.pdf',
        depth: 2,
      };
      const patternGlasses: ImageElement = {
        type: 'ImageElement',
        x: 313,
        y: 204,
        imageURL: 'assets/templates_jal_travel_book/03007/G/03007-G01.png',
        imagePDFURL: 'assets/templates_jal_travel_book/03007/G/03007-G01.pdf',
        depth: 1,
      };
      objects.push(
        ...[
          resolveAssetElement(
            skinColorConfig[bookData.clothColor][bookData.skinColor],
            patternSkin,
          ),
          resolveAssetElement(
            hairColorConfig[bookData.hairColor][bookData.hairStyle],
            patternHair,
          ),
        ],
      );

      if (bookData.glasses) {
        objects.push(patternGlasses);
      }
      return objects;
    },
    pages: [
      {
        pageId: '3',
        pageImageURL: 'assets/templates_jal_travel_book/03007/03007.jpg',
        text: [jp.SEGMENT_3_text_1, jp.SEGMENT_3_text_2, jp.SEGMENT_3_text_3],
        data: {},
      },
    ],
  } as TSegment,

  {
    segmentId: '4',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    objectProcessor: (bookData) => {
      const objects: (ImageElement | TextElement)[] = [];
      const patternSkin: ImageElement = {
        type: 'ImageElement',
        x: 80,
        y: 235,
        imageURL:
          'assets/templates_jal_travel_book/04008/M/04008-M{{assetSkin}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/04008/M/04008-M{{assetSkin}}.pdf',
        depth: 1,
      };
      const patternHair: ImageElement = {
        type: 'ImageElement',
        x: 80,
        y: 235,
        imageURL:
          'assets/templates_jal_travel_book/04008/H/04008-H{{assetHair}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/04008/H/04008-H{{assetHair}}.pdf',
        depth: 2,
      };
      const patternGlasses: ImageElement = {
        type: 'ImageElement',
        x: 80,
        y: 235,
        imageURL: 'assets/templates_jal_travel_book/04008/G/04008-G01.png',
        imagePDFURL: 'assets/templates_jal_travel_book/04008/G/04008-G01.pdf',
        depth: 1,
      };
      objects.push(
        ...[
          resolveAssetElement(
            skinColorConfig[bookData.clothColor][bookData.skinColor],
            patternSkin,
          ),
          resolveAssetElement(
            hairColorConfig[bookData.hairColor][bookData.hairStyle],
            patternHair,
          ),
        ],
      );

      if (bookData.glasses) {
        objects.push(patternGlasses);
      }
      return objects;
    },
    pages: [
      {
        pageId: '4',
        pageImageURL: 'assets/templates_jal_travel_book/04008/04008.jpg',
        text: [jp.SEGMENT_4_text_1],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '5',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    pages: [
      {
        pageId: '5',
        pageImageURL: 'assets/templates_jal_travel_book/05009/05009.jpg',
        text: [jp.SEGMENT_5_text_1, jp.SEGMENT_5_text_2],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '6',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    objectProcessor: (bookData) => {
      const objects: (ImageElement | TextElement)[] = [];
      const patternSkin: ImageElement = {
        type: 'ImageElement',
        x: -1,
        y: 65,
        imageURL:
          'assets/templates_jal_travel_book/06010/M/06010-M{{assetSkin}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/06010/M/06010-M{{assetSkin}}.pdf',
        depth: 1,
      };
      const patternHair: ImageElement = {
        type: 'ImageElement',
        x: -1,
        y: 65,
        imageURL:
          'assets/templates_jal_travel_book/06010/H/06010-H{{assetHair}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/06010/H/06010-H{{assetHair}}.pdf',
        depth: 2,
      };
      const patternGlasses: ImageElement = {
        type: 'ImageElement',
        x: -1,
        y: 65,
        imageURL: 'assets/templates_jal_travel_book/06010/G/06010-G01.png',
        imagePDFURL: 'assets/templates_jal_travel_book/06010/G/06010-G01.pdf',
        depth: 1,
      };
      objects.push(
        ...[
          resolveAssetElement(
            skinColorConfig[bookData.clothColor][bookData.skinColor],
            patternSkin,
          ),
          resolveAssetElement(
            hairColorConfig[bookData.hairColor][bookData.hairStyle],
            patternHair,
          ),
        ],
      );

      if (bookData.glasses) {
        objects.push(patternGlasses);
      }
      return objects;
    },
    pages: [
      {
        pageId: '6',
        pageImageURL: 'assets/templates_jal_travel_book/06010/06010.jpg',
        text: [jp.SEGMENT_6_text_1, jp.SEGMENT_6_text_2, jp.SEGMENT_6_text_3],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '7',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    objectProcessor: (bookData) => {
      const objects: (ImageElement | TextElement)[] = [];
      const patternSkin: ImageElement = {
        type: 'ImageElement',
        x: -7,
        y: -10,
        imageURL:
          'assets/templates_jal_travel_book/07011/M/07011-M{{assetSkin}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/07011/M/07011-M{{assetSkin}}.pdf',
        depth: 1,
      };

      const patternCountry: ImageElement = {
        type: 'ImageElement',
        x: -11,
        y: -15,
        imageURL:
          'assets/templates_jal_travel_book/07011/C/07011-C{{assetDestination}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/07011/C/07011-C{{assetDestination}}.pdf',
        depth: 1,
      };

      objects.push(
        ...[
          resolveAssetElement(
            skinColorConfig[bookData.clothColor][bookData.skinColor],
            patternSkin,
          ),
          resolveAssetElement(
            {
              type: 'AssetElement',
              asset: {
                assetDestination: getDestinationsMapValue(
                  bookData.destinations,
                ),
              },
            },
            patternCountry,
          ),
        ],
      );
      return objects;
    },
    pages: [
      {
        pageId: '7',
        pageImageURL: 'assets/templates_jal_travel_book/07011/07011.jpg',
        text: [jp.SEGMENT_7_text_1, jp.SEGMENT_7_text_2],
      },
    ],
  } as TSegment,
  {
    segmentId: '8',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    pages: [
      {
        pageId: '8',
        pageImageURL: 'assets/templates_jal_travel_book/08012/08012.jpg',
        text: [
          jp.SEGMENT_8_text_1,
          jp.SEGMENT_8_text_2_1,
          jp.SEGMENT_8_text_2_2,
          jp.SEGMENT_8_text_3,
          jp.SEGMENT_8_text_4,
        ],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '9',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    pages: [
      {
        pageId: '9',
        pageImageURL: 'assets/templates_jal_travel_book/09013/09013.jpg',
        text: [
          jp.SEGMENT_9_text_1,
          jp.SEGMENT_9_text_2,
          jp.SEGMENT_9_text_3,
          jp.SEGMENT_9_text_4,
        ],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '10',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    objectProcessor: (bookData) => {
      const objects: (ImageElement | TextElement)[] = [];
      const patternSkin: ImageElement = {
        type: 'ImageElement',
        x: 543,
        y: 128,
        imageURL:
          'assets/templates_jal_travel_book/10014/M/10014-M{{assetSkin}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/10014/M/10014-M{{assetSkin}}.pdf',
        depth: 1,
      };
      const patternHair: ImageElement = {
        type: 'ImageElement',
        x: 543,
        y: 128,
        imageURL:
          'assets/templates_jal_travel_book/10014/H/10014-H{{assetHair}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/10014/H/10014-H{{assetHair}}.pdf',
        depth: 2,
      };
      const patternGlasses: ImageElement = {
        type: 'ImageElement',
        x: 543,
        y: 128,
        imageURL: 'assets/templates_jal_travel_book/10014/G/10014-G01.png',
        imagePDFURL: 'assets/templates_jal_travel_book/10014/G/10014-G01.pdf',
        depth: 1,
      };
      objects.push(
        ...[
          resolveAssetElement(
            skinColorConfig[bookData.clothColor][bookData.skinColor],
            patternSkin,
          ),
          resolveAssetElement(
            hairColorConfig[bookData.hairColor][bookData.hairStyle],
            patternHair,
          ),
        ],
      );

      if (bookData.glasses) {
        objects.push(patternGlasses);
      }
      return objects;
    },
    pages: [
      {
        pageId: '10',
        pageImageURL: 'assets/templates_jal_travel_book/10014/10014.jpg',
        text: [jp.SEGMENT_10_text_1],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '11',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    pages: [
      {
        pageId: '11',
        pageImageURL: 'assets/templates_jal_travel_book/11015/11015.jpg',
        text: [
          jp.SEGMENT_11_text_1,
          jp.SEGMENT_11_text_2,
          jp.SEGMENT_11_text_3,
        ],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '12',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    objectProcessor: (bookData) => {
      const objects: (ImageElement | TextElement)[] = [];
      const [dest1, dest2] = getSortedDestinations(bookData.destinations);
      let patternSkin: ImageElement;
      let patternHair: ImageElement;
      let patternGlasses: ImageElement;

      if (dest1 === 'Hawai') {
        patternSkin = {
          type: 'ImageElement',
          x: -10,
          y: 108,
          imageURL:
            'assets/templates_jal_travel_book/12016/M/12016-M{{assetSkin}}.png',
          imagePDFURL:
            'assets/templates_jal_travel_book/12016/M/12016-M{{assetSkin}}.pdf',
          depth: 1,
        };
        patternHair = {
          type: 'ImageElement',
          x: -10,
          y: 108,
          imageURL:
            'assets/templates_jal_travel_book/12016/H/12016-H{{assetHair}}.png',
          imagePDFURL:
            'assets/templates_jal_travel_book/12016/H/12016-H{{assetHair}}.pdf',
          depth: 2,
        };
        patternGlasses = {
          type: 'ImageElement',
          x: -10,
          y: 108,
          imageURL: 'assets/templates_jal_travel_book/12016/G/12016-G01.png',
          imagePDFURL: 'assets/templates_jal_travel_book/12016/G/12016-G01.pdf',
          depth: 1,
        };
      } else if (dest1 === 'England') {
        patternSkin = {
          type: 'ImageElement',
          x: 543,
          y: 128,
          imageURL:
            'assets/templates_jal_travel_book/12017/M/12017-M{{assetSkin}}.png',
          imagePDFURL:
            'assets/templates_jal_travel_book/12017/M/12017-M{{assetSkin}}.pdf',
          depth: 1,
        };
        patternHair = {
          type: 'ImageElement',
          x: 543,
          y: 128,
          imageURL:
            'assets/templates_jal_travel_book/12017/H/12017-H{{assetHair}}.png',
          imagePDFURL:
            'assets/templates_jal_travel_book/12017/H/12017-H{{assetHair}}.pdf',
          depth: 2,
        };
        patternGlasses = {
          type: 'ImageElement',
          x: 543,
          y: 128,
          imageURL: 'assets/templates_jal_travel_book/12017/G/12017-G01.png',
          imagePDFURL: 'assets/templates_jal_travel_book/12017/G/12017-G01.pdf',
          depth: 1,
        };
      } else if (dest1 === 'Finland') {
        patternSkin = {
          type: 'ImageElement',
          x: 543,
          y: 128,
          imageURL:
            'assets/templates_jal_travel_book/12018/M/12018-M{{assetSkin}}.png',
          imagePDFURL:
            'assets/templates_jal_travel_book/12018/M/12018-M{{assetSkin}}.pdf',
          depth: 1,
        };
        patternHair = {
          type: 'ImageElement',
          x: 543,
          y: 128,
          imageURL:
            'assets/templates_jal_travel_book/12018/H/12018-H{{assetHair}}.png',
          imagePDFURL:
            'assets/templates_jal_travel_book/12018/H/12018-H{{assetHair}}.pdf',
          depth: 2,
        };
        patternGlasses = {
          type: 'ImageElement',
          x: 543,
          y: 128,
          imageURL: 'assets/templates_jal_travel_book/12018/G/12018-G01.png',
          imagePDFURL: 'assets/templates_jal_travel_book/12018/G/12018-G01.pdf',
          depth: 1,
        };
      } else {
        throw new Error(`dest1 is ${dest1}`);
      }

      objects.push(
        ...[
          resolveAssetElement(
            skinColorConfig[bookData.clothColor][bookData.skinColor],
            patternSkin,
          ),
          resolveAssetElement(
            hairColorConfig[bookData.hairColor][bookData.hairStyle],
            patternHair,
          ),
        ],
      );

      if (bookData.glasses) {
        objects.push(patternGlasses);
      }
      return objects;
    },

    getPages: (bookData: IBookTravelData) =>
      segment12Pages[getSortedDestinations(bookData.destinations)[0]],
    pages: [],
  } as TSegment,
  {
    segmentId: '13',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),

    getPages: (bookData: IBookTravelData) =>
      segment13Pages[getSortedDestinations(bookData.destinations)[1]],
    pages: [],
  } as TSegment,
  {
    segmentId: '14',
    pages: [
      {
        pageId: '14',
        pageImageURL: 'assets/templates_jal_travel_book/14018/14018.jpg',
        text: [jp.SEGMENT_0_test],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '15',
    pages: [
      {
        pageId: '15',
        pageImageURL: 'assets/templates_jal_travel_book/15019/15019.jpg',
        text: [jp.SEGMENT_0_test],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '16',
    pages: [
      {
        pageId: '16',
        pageImageURL: 'assets/templates_jal_travel_book/16020/16020.jpg',
        text: [jp.SEGMENT_0_test],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '17',
    pages: [
      {
        pageId: '17',
        pageImageURL: 'assets/templates_jal_travel_book/17021/17021.jpg',
        text: [jp.SEGMENT_0_test],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '18',
    pages: [
      {
        pageId: '18',
        pageImageURL: 'assets/templates_jal_travel_book/18022/18022.jpg',
        text: [jp.SEGMENT_0_test],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '19',
    pages: [
      {
        pageId: '19',
        pageImageURL: 'assets/templates_jal_travel_book/19023/19023.jpg',
        text: [jp.SEGMENT_0_test],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '20',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    objectProcessor: (bookData) => {
      const objects: (ImageElement | TextElement)[] = [];
      const patternSkin: ImageElement = {
        type: 'ImageElement',
        x: 302,
        y: 543,
        imageURL:
          'assets/templates_jal_travel_book/20022/M/20022-M{{assetSkin}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/20022/M/20022-M{{assetSkin}}.pdf',
        depth: 1,
      };
      const patternHair: ImageElement = {
        type: 'ImageElement',
        x: 302,
        y: 543,
        imageURL:
          'assets/templates_jal_travel_book/20022/H/20022-H{{assetHair}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/20022/H/20022-H{{assetHair}}.pdf',
        depth: 2,
      };
      const patternGlasses: ImageElement = {
        type: 'ImageElement',
        x: 302,
        y: 543,
        imageURL: 'assets/templates_jal_travel_book/20022/G/20022-G01.png',
        imagePDFURL: 'assets/templates_jal_travel_book/20022/G/20022-G01.pdf',
        depth: 1,
      };
      objects.push(
        ...[
          resolveAssetElement(
            skinColorConfig[bookData.clothColor][bookData.skinColor],
            patternSkin,
          ),
          resolveAssetElement(
            hairColorConfig[bookData.hairColor][bookData.hairStyle],
            patternHair,
          ),
        ],
      );

      if (bookData.glasses) {
        objects.push(patternGlasses);
      }
      return objects;
    },
    pages: [
      {
        pageId: '20',
        pageImageURL: 'assets/templates_jal_travel_book/20022/20022.jpg',
        text: [jp.SEGMENT_20_text_1, jp.SEGMENT_20_text_2],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '21',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    pages: [
      {
        pageId: '21',
        pageImageURL: 'assets/templates_jal_travel_book/21023/21023.jpg',
        text: [jp.SEGMENT_21_text_1, jp.SEGMENT_21_text_2],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '22',
    pages: [
      {
        pageId: '22',
        pageImageURL: 'assets/templates_jal_travel_book/22026/22026.jpg',
        text: [jp.SEGMENT_0_test],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '23',
    pages: [
      {
        pageId: '23',
        pageImageURL: 'assets/templates_jal_travel_book/23027/23027.jpg',
        text: [jp.SEGMENT_0_test],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '24',
    pages: [
      {
        pageId: '24',
        pageImageURL: 'assets/templates_jal_travel_book/24028/24028.jpg',
        text: [jp.SEGMENT_0_test],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '25',
    pages: [
      {
        pageId: '25',
        pageImageURL: 'assets/templates_jal_travel_book/25029/25029.jpg',
        text: [jp.SEGMENT_0_test],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '26',
    pages: [
      {
        pageId: '26',
        pageImageURL: 'assets/templates_jal_travel_book/26030/26030.jpg',
        text: [jp.SEGMENT_0_test],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '27',
    pages: [
      {
        pageId: '27',
        pageImageURL: 'assets/templates_jal_travel_book/27031/27031.jpg',
        text: [jp.SEGMENT_0_test],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '28',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    pages: [
      {
        pageId: '28',
        pageImageURL: 'assets/templates_jal_travel_book/28024/28024.jpg',
        text: [jp.SEGMENT_28_text_1, jp.SEGMENT_28_text_2],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '29',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    objectProcessor: (bookData) => {
      const objects: (ImageElement | TextElement)[] = [];
      const patternSkin: ImageElement = {
        type: 'ImageElement',
        x: 421,
        y: 239,
        imageURL:
          'assets/templates_jal_travel_book/29025/M/29025-M{{assetSkin}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/29025/M/29025-M{{assetSkin}}.pdf',
        depth: 1,
      };
      const patternHair: ImageElement = {
        type: 'ImageElement',
        x: 421,
        y: 239,
        imageURL:
          'assets/templates_jal_travel_book/29025/H/29025-H{{assetHair}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/29025/H/29025-H{{assetHair}}.pdf',
        depth: 2,
      };
      const patternGlasses: ImageElement = {
        type: 'ImageElement',
        x: 421,
        y: 239,
        imageURL: 'assets/templates_jal_travel_book/29025/G/29025-G01.png',
        imagePDFURL: 'assets/templates_jal_travel_book/29025/G/29025-G01.pdf',
        depth: 1,
      };
      objects.push(
        ...[
          resolveAssetElement(
            skinColorConfig[bookData.clothColor][bookData.skinColor],
            patternSkin,
          ),
          resolveAssetElement(
            hairColorConfig[bookData.hairColor][bookData.hairStyle],
            patternHair,
          ),
        ],
      );

      if (bookData.glasses) {
        objects.push(patternGlasses);
      }
      return objects;
    },
    pages: [
      {
        pageId: '29',
        pageImageURL: 'assets/templates_jal_travel_book/29025/29025.jpg',
        text: [
          jp.SEGMENT_29_text_1,
          jp.SEGMENT_29_text_2,
          jp.SEGMENT_29_text_3,
        ],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '30',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    objectProcessor: (bookData) => {
      const objects: (ImageElement | TextElement)[] = [];
      const patternSkin: ImageElement = {
        type: 'ImageElement',
        x: 0,
        y: 131,
        imageURL:
          'assets/templates_jal_travel_book/30026/M/30026-M{{assetSkin}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/30026/M/30026-M{{assetSkin}}.pdf',
        depth: 1,
      };
      const patternCountry: ImageElement = {
        type: 'ImageElement',
        x: 446,
        y: 26,
        imageURL:
          'assets/templates_jal_travel_book/30026/C/30026-C{{assetDestination}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/30026/C/30026-C{{assetDestination}}.pdf',
        depth: 1,
      };

      objects.push(
        ...[
          resolveAssetElement(
            skinColorConfig[bookData.clothColor][bookData.skinColor],
            patternSkin,
          ),
          resolveAssetElement(
            {
              type: 'AssetElement',
              asset: {
                assetDestination: getDestinationsMapValue(
                  bookData.destinations,
                ),
              },
            },
            patternCountry,
          ),
        ],
      );

      return objects;
    },
    pages: [
      {
        pageId: '30',
        pageImageURL: 'assets/templates_jal_travel_book/30026/30026.jpg',
        text: [
          jp.SEGMENT_30_text_1,
          jp.SEGMENT_30_text_2,
          jp.SEGMENT_30_text_3,
        ],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '31',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    pages: [
      {
        pageId: '31',
        pageImageURL: 'assets/templates_jal_travel_book/31027/31027.jpg',
        text: [
          jp.SEGMENT_31_text_1,
          jp.SEGMENT_31_text_2,
          jp.SEGMENT_31_text_3,
        ],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '32',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    pages: [
      {
        pageId: '32',
        pageImageURL: 'assets/templates_jal_travel_book/32028/32028.jpg',
        text: [jp.SEGMENT_32_text_1, jp.SEGMENT_32_text_2],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '33',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    objectProcessor: (bookData) => {
      const objects: (ImageElement | TextElement)[] = [];
      const patternSkin: ImageElement = {
        type: 'ImageElement',
        x: 528,
        y: 39,
        imageURL:
          'assets/templates_jal_travel_book/33029/M/33029-M{{assetSkin}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/33029/M/33029-M{{assetSkin}}.pdf',
        depth: 1,
      };
      const patternHair: ImageElement = {
        type: 'ImageElement',
        x: 528,
        y: 39,
        imageURL:
          'assets/templates_jal_travel_book/33029/H/33029-H{{assetHair}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/33029/H/33029-H{{assetHair}}.pdf',
        depth: 2,
      };
      const patternGlasses: ImageElement = {
        type: 'ImageElement',
        x: 528,
        y: 39,
        imageURL: 'assets/templates_jal_travel_book/33029/G/33029-G01.png',
        imagePDFURL: 'assets/templates_jal_travel_book/33029/G/33029-G01.pdf',
        depth: 1,
      };
      objects.push(
        ...[
          resolveAssetElement(
            skinColorConfig[bookData.clothColor][bookData.skinColor],
            patternSkin,
          ),
          resolveAssetElement(
            hairColorConfig[bookData.hairColor][bookData.hairStyle],
            patternHair,
          ),
        ],
      );

      if (bookData.glasses) {
        objects.push(patternGlasses);
      }
      return objects;
    },
    pages: [
      {
        pageId: '33',
        pageImageURL: 'assets/templates_jal_travel_book/33029/33029.jpg',
        text: [jp.SEGMENT_33_text_1, jp.SEGMENT_33_text_2],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '34',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    pages: [
      {
        pageId: '34',
        pageImageURL: 'assets/templates_jal_travel_book/34030/34030.jpg',
        text: [jp.SEGMENT_34_text_1, jp.SEGMENT_34_text_2],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '35',
    pages: [
      {
        pageId: '35',
        pageImageURL: 'assets/templates_jal_travel_book/00001/00001.jpg',
        data: {},
      },
    ],
  } as TSegment,
] as const;

/**
 * * `segments` usage
 *
 * ```
 * <lib-text-book-view
 *   [segment]="segments[0]"
 *   [debug]="false"
 * ></lib-text-book-view>
 * ```
 */

export default segments;

export * from './_utils';
