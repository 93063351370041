<div class="container">
  <div class="container__title">
    <h1 class="title">主人公になって、旅しよう</h1>
  </div>
  <div class="container__content">
    <p class="content">
      JALが贈る、”
      <br
        *ngIf="mediaService.isLtLg$ | async"
      />オ主人公になれる旅絵本。<br />子どもの想像力と<br
        *ngIf="mediaService.isLtLg$ | async"
      />世可能性を広げる物語。
    </p>
  </div>
  <div class="container__button">
    <button
      class="button"
      (click)="onButtonClick()"
    >
      <span class="button__text">絵本を作る</span>
    </button>
  </div>
</div>
