<div class="container">
  <div class="container__title">
    <h1
      class="title"
      [innerHTML]="book.caption"
    ></h1>
  </div>
  <div class="container__info">
    <div class="info">
      <div class="info__gallery">
        <lib-gallery [photos]="book.photos"></lib-gallery>
      </div>
      <div class="info__description">
        <div class="description">
          <div class="description__section">
            <lib-notice
              [alias]="book.alias"
              *ngIf="
                discountService.activeDiscountCampaign$
                  | async as discountCampaign;
                else noticeInactiveDiscountCampaigns
              "
              [caption]="book.notice.caption"
              [description]="book.notice.description"
              [points]="book.notice.points"
              [prices]="book.prices"
              [discount]="getBooksDiscount(discountCampaign, book.alias)"
              buttonText="ギフトコード対応"
              (buttonClick)="onNoticeButtonClick()"
            ></lib-notice>

            <ng-template #noticeInactiveDiscountCampaigns>
              <lib-notice
                [alias]="book.alias"
                [caption]="book.notice.caption"
                [description]="book.notice.description"
                [points]="book.notice.points"
                [prices]="book.prices"
                buttonText="ギフトコード対応"
                (buttonClick)="onNoticeButtonClick()"
              ></lib-notice>
            </ng-template>
          </div>
          <div
            *ngIf="book.review as review"
            class="description__section"
          >
            <section class="reviews">
              <div class="reviews__content">
                <p
                  *ngFor="let text of review.text"
                  class="reviews-text"
                >
                  {{ text }}
                </p>
              </div>
              <footer class="reviews__footer">
                <a
                  href="#reviews"
                  class="reviews-text"
                  >レビューを読む</a
                >
                <img
                  src="assets/images/reviews-stars.png"
                  alt="★★★★★"
                  class="reviews-stars"
                />
              </footer>
            </section>
          </div>
          <div
            *ngIf="media.isActive('gt-lg') && book.points?.length"
            class="description__section"
          >
            <lib-points [points]="$any(book.points)"></lib-points>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container__form">
    <lib-book-form
      class="form"
      [alias]="book.alias"
      [inputs]="book.inputs"
      (submitted)="submitted($event)"
      [getErrorText]="getErrorText"
    ></lib-book-form>
  </div>
  <div class="container__date">
    <lib-section-message-date></lib-section-message-date>
  </div>
  <div
    *ngIf="media.isActive('lt-xl') && book.points?.length"
    class="container__points"
  >
    <lib-points [points]="$any(book.points)"></lib-points>
  </div>
  <div
    *ngIf="book.banner"
    class="container__banner"
  >
    <app-banner
      [alias]="book.alias"
      [imgSrc]="book.banner.imgSrc"
      [imgSrcLtMd]="book.banner.imgSrcLtMd"
      [text]="book.banner.text"
      [textLtMd]="book.banner.textLtMd"
      [textColor]="book.banner.textColor"
      [textSize]="book.banner.textSize"
    ></app-banner>
  </div>
  <div
    *ngIf="book.description"
    class="container__description"
  >
    <lib-description [description]="book.description"></lib-description>
  </div>
  <!--  <div class="container__section-see-other">-->
  <!--    &lt;!&ndash; <app-section-see-other></app-section-see-other> &ndash;&gt;-->
  <!--    app-section-see-other-->
  <!--  </div>-->
  <div
    *ngIf="book.reviews"
    id="reviews"
    class="container__section-reviews"
  >
    <!-- <app-section-reviews-yotpo-book
      [alias]="book.alias"
      [caption]="book.caption"
      [prices]="book.prices"
      [photo]="book.previewPhoto"
    ></app-section-reviews-yotpo-book> -->
  </div>
  <div class="container__section-features">
    <lib-section-features></lib-section-features>
  </div>
  <!--  <div class="container__section-footer-banner">-->
  <!--    &lt;!&ndash; <app-section-banner-mermaid></app-section-banner-mermaid> &ndash;&gt;-->
  <!--    app-section-banner-mermaid-->
  <!--  </div>-->
  <div class="container__section-steps">
    <lib-section-steps></lib-section-steps>
  </div>
  <div class="container__section-faq">
    <lib-section-faq
      [faqData]="faqDataCondensed"
      (buttonClick)="onFaqButtonClick()"
      [condensed]="true"
    ></lib-section-faq>
  </div>
</div>
