<div class="wrap">
  <app-section-header-gap
    class="wrap__section-header-gap"
    *ngIf="media.isLtXl$ | async"
  ></app-section-header-gap>
  <app-section-about-banner></app-section-about-banner>
  <div class="wrap__section-description">
    <div class="description">
      <p class="description__passage">
        私たちJALは、長年にわたり、多くの方々に旅の機会を提供してきました。
      </p>
      <p class="description__passage">
        今では、誰もが飛行機に乗り、新しい世界へと旅立つことができる世の中です。
      </p>
      <p class="description__passage">
        しかし、私たちは実際に旅を始める理由は、未知の世界を知りたいという心にあると考えています。
      </p>
      <p class="description__passage">
        物語を通して、子どもたちは新たな発見をし、驚きや感動を経験することができます。
      </p>
      <p class="description__passage">
        そのワクワクが、未知の世界へと飛び立つ勇気となるように。
      </p>
      <p class="description__passage">
        私たちは、「世界はとても面白いところだよ、大丈夫だよ」と、積極的に子どもたちに伝えたいと思っています。
      </p>
      <br />
      <br />
      <p class="description__highlighted-passage">
        子供たちの心の中で広がる世界が、希望と夢に満ちた世界を見つけられるようにと願いを込めて、一冊一冊の絵本をお届けします。
      </p>
    </div>
  </div>
  <div class="wrap__section-photo">
    <picture class="photo">
      <source
        media="(max-width: 767px)"
        srcset="assets/images/team_mobile.jpg"
      />
      <img
        src="assets/images/about-page-review-photo.png"
        alt="Banner background image."
      />
    </picture>
  </div>
<!--  <div class="wrap__section-message">-->
<!--    <app-section-message></app-section-message>-->
<!--  </div>-->
<!--  <div class="wrap__section-collage">-->
<!--    <picture class="collage">-->
<!--      <source-->
<!--        media="(max-width: 767px)"-->
<!--        srcset="assets/images/collage_mobile.jpg"-->
<!--      />-->
<!--      <img-->
<!--        src="assets/images/collage.jpg"-->
<!--        alt="Banner background image."-->
<!--      />-->
<!--    </picture>-->
<!--  </div>-->
<!--  <div class="wrap__section-story">-->
<!--    <app-section-story></app-section-story>-->
<!--  </div>-->
<!--  <div class="wrap__section-business-card">-->
<!--    <app-section-business-card></app-section-business-card>-->
<!--  </div>-->
</div>
